import { Observable } from 'rxjs';

import { Design } from '../entities/design';
import { Project } from '../entities/project';
import { DesignTemplateFolderBase } from '../entities/template';

export enum ProjectAndDesignView {
    allDesigns,
    templates,
    drafts,
    projects
}

export enum ProjectAndDesignDesignGroup {
    none,
    list
}

export interface ISubscriptionInfo {
    AuthorizationEntryList: {
        AccountID: string;
        City: string;
        ContactFirstName: string;
        ContactID: string;
        ContactIDs: string;
        ContactLastName: string;
        ContactOriginID: string;
        Country: string;
        CustomerID: string;
        CustomerName: string;
        CustomerOriginID: string;
        EmployeeID: string;
        Licenses: string;
        PostalCode: string;
        Roles: string;
        Scopes: string;
        SecondaryName: string;
        Street: string;
        TenantID: string;
    }[];
    ClientID: string;
    Country: string;
    CountryOfResidence: string;
    CustomerID: string;
    CustomerOriginID: string;
    LogonID: string;
    UID: string;
}

/**
 * Parameters: activationToken, userId and username are used only in development (agitavit environment) and offline versions
 *
 * activationToken is used only in offline version (for all environments)
 */
export interface IAuthentication {
    accessToken: string;
    customerId?: string;
    customerOriginId?: string;
    country?: string;
    countryOfResidence?: string;
    license: string;
    contactId: string;
    contactOriginId: string;

    // parameters for development (agitavit environment) and offline versions
    userId: string;
    userName: string;

    // for offline only
    externalUserId: string;
    externalUserName: string;

    // raw HC data
    subscription_info: ISubscriptionInfo;
}

export abstract class UserServiceBase<TDesign> {
    public abstract renameFile: boolean;
    public abstract openDesign: boolean;
    public abstract projectAndDesignView: ProjectAndDesignView;
    public abstract readonly design: TDesign;
    public abstract releaseAllFloatingLicenses(ignoreErrors: boolean): void;
    public abstract getHeaders(url: string, forceIncludeAuthenticationHeaders: boolean): Record<string, string>;
    public abstract changeDesign(project?: Project, design?: Design): void;
    public abstract getUserName(): string;
    public abstract isInternalHiltiUser(): boolean;

    /**
    * @deprecated DO NOT USE FOR NEW CODE. Will be removed with cleanup task: BUDQBP-36273.
    */
    public abstract collectHeadersAndCallProjectCloseBrowserUnload(url: string, jsonData: string): Promise<void>;

    public abstract authenticated: Observable<IAuthentication>;
    /**
     * @deprecated don't use in pe-ui, will be moved to pe-ui-pe
     */
    public abstract isAsadEnabled: boolean;
    public abstract authentication: IAuthentication;
    public abstract get templateFolder() : DesignTemplateFolderBase;
    public abstract isExternalOnlineRussianUser: boolean;
    public abstract get hasfloatingLimitReached(): boolean;
    public abstract get hasFreeLicense(): boolean;
    public abstract get hasOnlyBasic(): boolean;
    public abstract get project(): Project;
    public abstract get isInternalLicenseSwitchAvailable(): boolean;
    public abstract get isCreateTemplate() : boolean;
    public abstract setIsCreateTemplate(value?: boolean) : void;
    public abstract setTemplateFolder(templateFolder?: DesignTemplateFolderBase) : void;
}

export class UserServiceInjected<TDesign> extends UserServiceBase<TDesign> {

    protected baseService!: UserServiceBase<TDesign>;

    public setBaseService(baseService: UserServiceBase<TDesign>) {
        this.baseService = baseService;

        this.getHeaders = baseService.getHeaders.bind(baseService);
        this.releaseAllFloatingLicenses = baseService.releaseAllFloatingLicenses.bind(baseService);
        this.changeDesign = baseService.changeDesign.bind(baseService);
        this.getUserName = baseService.getUserName.bind(baseService);
        this.isInternalHiltiUser = baseService.isInternalHiltiUser.bind(baseService);
        this.collectHeadersAndCallProjectCloseBrowserUnload = baseService.collectHeadersAndCallProjectCloseBrowserUnload.bind(baseService);
        this.setIsCreateTemplate = baseService.setIsCreateTemplate.bind(baseService);
        this.setTemplateFolder = baseService.setTemplateFolder.bind(baseService);
    }

    public get renameFile() {
        return this.baseService.renameFile;
    }

    public set renameFile(value: boolean) {
        this.baseService.renameFile = value;
    }

    public get openDesign() {
        return this.baseService.openDesign;
    }

    public set openDesign(value: boolean) {
        this.baseService.openDesign = value;
    }

    public get projectAndDesignView() {
        return this.baseService.projectAndDesignView;
    }

    public set projectAndDesignView(value: ProjectAndDesignView) {
        this.baseService.projectAndDesignView = value;
    }

    public get design(): TDesign {
        return this.baseService.design;
    }

    public get isCreateTemplate() {
        return this.baseService.isCreateTemplate;
    }

    public get authenticated() {
        return this.baseService.authenticated;
    }

    public get templateFolder() {
        return this.baseService.templateFolder;
    }

    /**
     * @deprecated don't use in pe-ui, will be moved to pe-ui-pe
     */
    public get isAsadEnabled() {
        return this.baseService.isAsadEnabled;
    }

    public get authentication() {
        return this.baseService.authentication;
    }

    public get isExternalOnlineRussianUser() {
        return this.baseService.isExternalOnlineRussianUser;
    }

    public get hasfloatingLimitReached() {
        return this.baseService.hasfloatingLimitReached;
    }

    public get hasFreeLicense() {
        return this.baseService.hasFreeLicense;
    }

    public get hasOnlyBasic() {
        return this.baseService.hasOnlyBasic;
    }

    public get project() {
        return this.baseService.project;
    }

    public get isInternalLicenseSwitchAvailable() {
        return this.baseService.isInternalLicenseSwitchAvailable;
    }

    // UserServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getHeaders(_url: string, _forceIncludeAuthenticationHeaders: boolean) {
        return {} as Record<string, string>;
    }

    public releaseAllFloatingLicenses(_ignoreErrors: boolean): void {
        return;
    }

    public changeDesign(_project?: Project | undefined, _design?: Design<unknown> | undefined): void {
        return;
    }

    public getUserName() {
        return '';
    }

    public isInternalHiltiUser() {
        return false;
    }

    /**
    * @deprecated DO NOT USE FOR NEW CODE. Will be removed with cleanup task: BUDQBP-36273.
    */
    public collectHeadersAndCallProjectCloseBrowserUnload(_url: string, _jsonData: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public setIsCreateTemplate(_value?: boolean) {
        return ;
    }

    public setTemplateFolder(_templateFolder?: DesignTemplateFolderBase){
        return ;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
