<div class="legend-item" *ngIf="hasLoads">
    <span class="pe-ui-common-sprite pe-ui-common-sprite-{{staticLoadsIcon}}"></span>
    <p class="name" [innerHtml]="staticLoads"></p>
</div>

<div class="legend-item" *ngIf="hasLoads">
    <span class="pe-ui-common-sprite pe-ui-common-sprite-{{dynamicLoadsIcon}}"></span>
    <p class="name" [innerHtml]="dynamicLoads"></p>
</div>

<div class="legend-item" *ngIf="hasVariable">
    <span class="pe-ui-common-sprite pe-ui-common-sprite-{{variableLoadsIcon}}"></span>
    <p class="name" [innerHtml]="variableLoads"></p>
</div>

<div class="legend-item" *ngIf="hasCharacteristicAndSustained">
    <span class="pe-ui-common-sprite pe-ui-common-sprite-load-icon-orange"></span>
    <p class="name" [innerHtml]="sustainedLoads"></p>
</div>

<div class="legend-item" *ngIf="hasLoadsInWorstPosition">
    <span class="pe-ui-common-sprite pe-ui-common-sprite-load-icon-load-in-worst-position"></span>
    <p class="name" [innerHtml]="loadsInWorstPosition"></p>
</div>
