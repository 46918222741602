import orderBy from 'lodash-es/orderBy';

import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation
} from '@angular/core';
import { Validators } from '@angular/forms';

import {
    ReportTemplateEntity
} from '../../generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { DropdownItem, DropdownProps } from '../dropdown/dropdown.common';

const customTemplateId = Number.MAX_VALUE;

@Component({
    templateUrl: './export-report-company-layout.component.html',
    styleUrls: ['./export-report-company-layout.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportReportCompanyLayoutComponent implements OnChanges {
    @Input()
    public templateDropdown!: DropdownProps<number>;
    @Input()
    public reportTemplate?: ReportTemplateEntity;
    @Input()
    public reportCompanyName!: string;
    @Output()
    public reportCompanyNameChange = new EventEmitter<string>();
    @Input()
    public reportAddress!: string;
    @Output()
    public reportAddressChange = new EventEmitter<string>();
    @Input()
    public reportContactPerson!: string;
    @Output()
    public reportContactPersonChange = new EventEmitter<string>();
    @Input()
    public reportPhoneNumber!: string;
    @Output()
    public reportPhoneNumberChange = new EventEmitter<string>();
    @Input()
    public reportEmail!: string;
    @Output()
    public reportEmailChange = new EventEmitter<string>();
    @Output()
    public reportEmailValid = new EventEmitter<boolean>();
    @Input()
    public reportTypeDropdown!: DropdownProps<number>;
    @Input()
    public languageDropdown!: DropdownProps<number>;
    @Input()
    public paperSizeDropdown!: DropdownProps<number>;
    @Input()
    public firstPageNumber!: number;
    @Output()
    public firstPageNumberChange = new EventEmitter<number>();
    @Input()
    public loadCombinationDropdown!: DropdownProps<string>;
    @Input()
    public loadCaseDropdown!: DropdownProps<number>;
    @Input()
    public loadCaseHandrailDropdown!: DropdownProps<number>;

    @Input()
    public reportTemplateDisabled!: boolean;
    @Input()
    public isLoadCombinationDropdownVisible!: boolean;
    @Input()
    public displayLoadCaseDropdown!: boolean;
    @Input()
    public handrailSafetyDesign!: boolean;
    @Input()
    public isExternalOnlineRussianUser!: boolean;

    @Input()
    public formatedReportType!: string;
    @Input()
    public formatedPaperSize!: string;


    @Input()
    public submitted = false;

    @Output()
    public reportTypeChangeToggle = new EventEmitter<ReportTemplateEntity | undefined>();

    public requiredValidator = [Validators.required];
    public emailValidator = [Validators.email];
    public maxSafeInteger = Number.MAX_SAFE_INTEGER;

    public isLoaded = false;

    constructor(
        public localizationService: LocalizationService,
        public userSettingsService: UserSettingsService,
        private modalService: ModalService,
        private changeDetector: ChangeDetectorRef,
        private reportTemplateService: ReportTemplateService
    ) { }


    private get sortedTemplates() {
        // Sort default as last one and then reverse
        return orderBy(this.reportTemplateService.templates, template => template.IsTemplate, 'desc');
    }

    ngOnChanges(): void {
        if (!this.isLoaded) {
            this.fillTemplatesDropdown();

            // Russian users don't have custom layout, so we have to switch to the first available option.
            if(this.isExternalOnlineRussianUser && this.templateDropdown.selectedValue == customTemplateId) {
                this.templateDropdown.selectedValue = this.sortedTemplates[0].Id;
                this.onSelectedTemplateChange();
            }

            this.isLoaded = true;
        }
    }

    public onSelectedTemplateChange() {
        if (this.templateDropdown.selectedValue != null) {
            if (this.templateDropdown.selectedValue == customTemplateId) {
                this.reportTemplate = undefined;
            }
            else {
                this.reportTemplate = this.reportTemplateService.templates.find((template) => template.Id === this.templateDropdown.selectedValue);
            }
            this.onReportTypeChange(this.reportTemplate);
        }
    }

    public onReportTypeChange(reportTemplate: ReportTemplateEntity | undefined) {
        this.reportTypeChangeToggle.emit(reportTemplate);
    }

    public editTemplate() {
        this.modalService.openReportTemplates(this.reportTemplate != null ? this.reportTemplate.Id : undefined).result
            .then((id: number) => {
                if (id != null){
                    this.fillTemplatesDropdown();
                    this.templateDropdown.selectedValue = id;
                    this.onSelectedTemplateChange();
                }
            });
    }

    public changeFirstPageNumber(value: number) {
        this.firstPageNumber = value;

        if (value == null) {
            this.changeDetector.detectChanges();
            this.firstPageNumber = 1;
        }

        this.firstPageNumberChange.emit(this.firstPageNumber);
    }

    private fillTemplatesDropdown() {
        this.templateDropdown.items = [];
        if (!this.isExternalOnlineRussianUser) {
            this.templateDropdown.items.push({
                value: customTemplateId,
                text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.Template.Custom')
            });
        }

        this.templateDropdown.items.push(...this.sortedTemplates.map((template) : DropdownItem<number> => {
            return {
                value: template.Id,
                text: template.Name,
                disabled: template.IsTemplate ? false : this.reportTemplateDisabled,
            };
        }));
    }
}
