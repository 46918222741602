import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { includeSprites } from '../../sprites';
import { LoadsLegendType } from './loads-legend.common';

@Component({
    templateUrl: './loads-legend.component.html',
    styleUrls: ['./loads-legend.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsLegendComponent implements OnInit {
    @Input()
    public loadsLegendType?: LoadsLegendType;

    @Input()
    public hasLoadsInWorstPosition = false;


    constructor(
        private localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>
    ) { }


    public get hasLoads() {
        return this.loadsLegendType != null
            && this.loadsLegendType != LoadsLegendType.None;
    }

    public get hasCharacteristicAndSustained() {
        return this.loadsLegendType == LoadsLegendType.CharacteristicAndSustained;
    }

    public get hasCharacteristic() {
        return this.loadsLegendType == LoadsLegendType.Characteristic;
    }

    public get hasVariable() {
        return this.loadsLegendType == LoadsLegendType.Fatigue;
    }

    public get staticLoads() {
        if (this.hasLoads) {
            const key = this.loadsLegendType == LoadsLegendType.Fatigue ?
                this.getLoadKey('Agito.Hilti.Profis3.Legend.PermanentLoad') :
                this.getLoadKey('Agito.Hilti.Profis3.Legend.StaticLoads');
            return key != null ? this.localizationService.getString(key) : null;
        }

        return null;
    }

    public get dynamicLoads() {
        if (this.hasLoads) {
            const key = this.loadsLegendType == LoadsLegendType.Fatigue ?
                this.getLoadKey('Agito.Hilti.Profis3.Legend.DynamicLoad') :
                this.getLoadKey('Agito.Hilti.Profis3.Legend.DynamicLoads');
            return key != null ? this.localizationService.getString(key) : null;
        }

        return null;
    }

    public get variableLoads() {
        if (this.hasLoads) {
            const key = this.getLoadKey('Agito.Hilti.Profis3.Legend.VariableLoad');
            return key != null ? this.localizationService.getString(key) : null;
        }

        return null;
    }

    public get sustainedLoads() {
        return this.localizationService.getString('Agito.Hilti.Profis3.Legend.DynamicLoads.Sustained');
    }

    public get loadsInWorstPosition() {
        if (this.hasLoadsInWorstPosition) {
            return this.localizationService.getString('Agito.Hilti.Profis3.Legend.LoadsInWorstPosition');
        }

        return null;
    }

    public get staticLoadsIcon() {
        return this.loadsLegendType == LoadsLegendType.Fatigue
            ? 'load-icon-black-bullet'
            : 'load-icon';
    }

    public get dynamicLoadsIcon() {
        return this.loadsLegendType == LoadsLegendType.Fatigue
            ? 'load-icon-red-bullet'
            : 'load-icon-blue';
    }

    public get variableLoadsIcon() {
        return 'load-icon-blue-bullet';
    }

    public ngOnInit() {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-load-icon',
            'sprite-load-icon-blue',
            'sprite-load-icon-orange',
            'sprite-load-icon-red',
            'sprite-load-icon-black-bullet',
            'sprite-load-icon-blue-bullet',
            'sprite-load-icon-red-bullet',
            'sprite-load-icon-load-in-worst-position'
        );
    }

    private getLoadKey(key: string) {
        switch (this.loadsLegendType) {
            case LoadsLegendType.CharacteristicAndSustained:
            case LoadsLegendType.Characteristic:
                key += '.Characteristic';
                break;

            case LoadsLegendType.Fatigue:
                key += '.Fatigue';
                break;

            case LoadsLegendType.Sustained:
                key += '.Sustained';
                break;

            case LoadsLegendType.None:
                return null;

            default:
                throw new Error('Unknown LoadsLegendType.');
        }

        return key;
    }
}
