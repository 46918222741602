import { BehaviorSubject } from 'rxjs';

export type FeatureFlagFromPeUi =
    'MaintenancePage' |
    'BulkReport' |
    'PE_EnableNewHomePage' |
    'LD_AutomatedPage' |
    'PE_SmartAnchorEnableTco' |
    'DataDogRequestLogging' |
    'Decking_Regions' |
    'PE_Smartin_Chat_Regions' |
    'PE_Smartin_Chat_Modules';

export abstract class FeatureVisibilityServiceBase<TFeature extends FeatureFlagFromPeUi | string> {
    public abstract registerFeature<TValue>(feature: TFeature, value: TValue): void;
    public abstract getFeatureValue<TValue>(_feature: TFeature, _defaultValue: TValue): TValue;
    public abstract isFeatureEnabled(feature: TFeature): boolean;
    public abstract get init(): BehaviorSubject<boolean>;
}

export abstract class FeaturesVisibilityServiceInjected<TFeature extends FeatureFlagFromPeUi | string> extends FeatureVisibilityServiceBase<TFeature | string> {
    protected baseService!: FeatureVisibilityServiceBase<TFeature | string>;

    public setBaseService(baseService: FeatureVisibilityServiceBase<TFeature | string>) {
        this.baseService = baseService;

        this.registerFeature = baseService.registerFeature.bind(baseService);
        this.getFeatureValue = baseService.getFeatureValue.bind(baseService);
        this.isFeatureEnabled = baseService.isFeatureEnabled.bind(baseService);
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public registerFeature<TValue>(_feature: TFeature, _value: TValue): void {
        // not called
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getFeatureValue<TValue>(_feature: TFeature, _defaultValue: TValue): TValue {
        return undefined as unknown as TValue;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public isFeatureEnabled(_feature: TFeature): boolean {
        return true;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */

    public get init(): BehaviorSubject<boolean> {
        return this.baseService.init;
    }
}
