export abstract class OfflineServiceBase {
    public abstract readonly isOffline: boolean;

    /**
     * Open the URL link from the INTERNET.
     * @param path - Path to the web page.
     */
    public abstract nativeExternalLinkOpen(path: string): void;

    /**
     * Opens the file from a specified local path or URL.
     * @param path - PAth to the file that needs to be opened.
     * @param fileName - Name of the file.
     * @param storeInTemp - Store the file temp folder, or show the save as dialog.
     * @param openAfterSave - Open the file after save.
     */
    public abstract nativeLocalPathOpen(path: string, fileName: string, storeInTemp: boolean, openAfterSave: boolean): void;
    public abstract nativeLocalFileOpen(path: string): void;
}

export class OfflineServiceInjected extends OfflineServiceBase {
    protected baseService!: OfflineServiceBase;

    public get isOffline() {
        return this.baseService.isOffline;
    }

    public setBaseService(baseService: OfflineServiceBase) {
        this.baseService = baseService;
        this.nativeExternalLinkOpen = baseService.nativeExternalLinkOpen.bind(baseService);
        this.nativeLocalPathOpen = baseService.nativeLocalPathOpen.bind(baseService);
        this.nativeLocalFileOpen = baseService.nativeLocalFileOpen.bind(baseService);
    }

    // LoggerServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public nativeExternalLinkOpen(_path: string): void {
        // Nothing to do.
    }

    public nativeLocalPathOpen(_path: string, _fileName: string, _storeInTemp: boolean, _openAfterSave: boolean): void {
        // Nothing to do.
    }

    public nativeLocalFileOpen(_path: string): void {
        // Nothing to do.
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
